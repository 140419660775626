<template>
  <div>
      <transition name="fadeIn" enter-active-class="animated fadeIn ">
        <router-view class=" "></router-view>
      </transition>
  </div>
</template>

<script>

export default {
  beforeCreate(){
    // document.getElementById('app').attributeStyleMap.clear();
    // el.attributeStyleMap.clear()
  }
  
}
</script>

<style lang="scss">
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/bootstrap-vue/src/index.scss';
</style>